import React from 'react';
import {Page, Text, View, Document, StyleSheet, Font} from '@react-pdf/renderer';
import {STIVISTO_NAME, STIVISTO_ADDRESS} from "../constants/invoiceReceiver";

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 50,
    },
    subheadline: {
        fontSize: 8,
    },
    text: {
        fontSize: 10,
    },
    title: {
        fontSize: 10,
    },
    subheader: {
        flexDirection: 'row',
        alignSelf: 'flex-end',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        padding: 10,
        borderRadius: 10,
        marginBottom: 50,
    },
    info: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    table: {
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: '#3a3a3a',
        borderRadius: 5,
    },
    tableHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#3a3a3a',
        padding: 5,
    },
    tableHeaderText: {
        color: 'white',
        alignSelf: 'center',
    },
    tableItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 5,
        alignItems: 'center',
    }
});

export const DefaultInvoicePDFTemplate = ({name, address, date, currency, product, total}) => {
    total = _parseTotal(total ?? "");
    return <Document creator={"Stivisto Invoice Generator"}>
        <Page size="A4" style={styles.page}>
            <View style={{margin: 50}}>
                <View style={styles.header}>
                    <View>
                        <Text style={styles.subheadline}>Bill From:{'\n\n'}</Text>
                        <Text style={[styles.text, {fontWeight: 'bold'}]} render={() => name}/>
                        <Text style={styles.text} render={() => address}/>
                    </View>
                    <Text style={{fontSize: 30}}>
                        INVOICE
                    </Text>
                </View>

                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View>
                        <Text style={styles.subheadline}>Bill To:{'\n\n'}</Text>
                        <Text style={[styles.text, {fontWeight: 'bold'}]}>
                            {STIVISTO_NAME}
                        </Text>
                        <Text style={styles.text}>
                            {STIVISTO_ADDRESS}
                        </Text>
                    </View>

                    <View style={styles.subheader}>
                        <View style={[styles.info, {marginRight: 10}]}>
                            <Text style={[styles.text, {marginBottom: 5}]}>Date: </Text>
                            <Text style={styles.text}>Balance Due: </Text>
                        </View>

                        <View style={styles.info}>
                            <Text style={[styles.text, {marginBottom: 5}]} render={() => date.toLocaleDateString("en-US", {day: "numeric", month: "short", year: "numeric"})}/>
                            <Text style={styles.text} render={() => currency + ' ' + total}/>
                        </View>
                    </View>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <Text style={[styles.text, styles.tableHeaderText]}>Product</Text>
                        <Text style={[styles.text, styles.tableHeaderText]}>Amount</Text>
                    </View>
                    <View style={styles.tableItem}>
                        <Text style={styles.text} render={() => product}/>
                        <Text style={styles.text} render={() => currency + ' ' + total}/>
                    </View>
                </View>


                <View style={{
                    flexDirection: 'row',
                    marginTop: 25,
                    alignSelf: 'flex-end',
                }}>
                    <Text style={{marginRight: 10, fontSize: 12}}>
                        Total:
                    </Text>
                    <Text style={{marginRight: 10, fontSize: 12}} render={() => currency + ' ' + total}/>
                </View>
            </View>
        </Page>
    </Document>;
}

/***
 * Function parses string-like number to price-format,
 * e.g. 1000 -> 1,000, 159000 -> 159,000, 100 -> 100
 * @param cost
 * @returns {string}
 */
const _parseTotal = (cost) => {
    return [...cost]
        .reverse()
        .map((item, idx) => {
            return idx + 1 !== cost.length &&(idx + 1) % 3 === 0 ? "," + item : item;
        })
        .reverse()
        .join('');
}
