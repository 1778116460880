import * as React from "react";
import './App.css';
import 'rsuite/styles/index.less';
import 'rsuite/dist/rsuite.min.css';
import {Switch, Route} from "react-router-dom";
import {Invoice} from "./screens/Invoice";


export default function App() {
    return (
        <Switch>
            <Route exact path="/:token?">
                <Invoice/>
            </Route>
        </Switch>
    );
}