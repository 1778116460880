import './styles.css';
import React, {useState, useLayoutEffect, useEffect} from "react";
import {useParams} from "react-router-dom";
import {currencies} from "../../constants/currencies";
import {
    Form,
    Button,
    Input,
    Container,
    Content,
    FlexboxGrid,
    Panel,
    DatePicker,
    Avatar,
    PanelGroup,
    SelectPicker,
    InputGroup,
    Message,
    toaster,
} from "rsuite";
import {STIVISTO_NAME, STIVISTO_ADDRESS} from "../../constants/invoiceReceiver";
import {LOGO_URL} from "../../constants/logo";
import {PDFDownloadLink} from '@react-pdf/renderer';
import {DefaultInvoicePDFTemplate} from '../../pdf_templates/default-invoice';

export const Invoice = () => {
    const {token} = useParams();
    const [rowSize, setRowSize] = useState(_getRowSize());
    const [date, setDate] = useState(new Date());
    const [currency, setCurrency] = useState(currencies[0].value);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [product, setProduct] = useState("");
    const [total, setTotal] = useState("");

    const validate = () => {
        if (!name || name.length < 1) {
            toaster.push(<Message type={"error"}>Name is a required field</Message>)
            return false;
        }

        if (!address || address.length < 1) {
            toaster.push(<Message type={"error"}>Address is a required field</Message>)
            return false;
        }

        if (!product || product.length < 1) {
            toaster.push(<Message type={"error"}>Product is a required field</Message>)
            return false;
        }

        if (!total || isNaN(total) || total < 1 ) {
            toaster.push(<Message type={"error"}>Total must be a positive number</Message>)
            return false;
        }
        return true;
    }
    const handleResize = () => {
        setRowSize(_getRowSize());
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useLayoutEffect(() => {
        if (token) {
            fetch(`/api/${token}`)
                .then(async (response) => {
                    const data = await response.json();
                    setName(data.name);
                    setCurrency(data.currency);
                    setAddress(data.address);
                    setProduct(data.product);
                    setTotal(data.total);
                });
        }
    }, [token]);
    const handleSubmit = async (e) => {
        if (!validate()) {
            return;
        }
        document.getElementsByClassName('pdf-download-link')[0].click();
    };
    const fileName = `stivisto_invoice__${new Date().toISOString().split('T')[0]}.pdf`;
    return <Container>
        <Content>
            <FlexboxGrid justify="center" style={{margin: 25}}>
                <FlexboxGrid.Item colspan={rowSize}>
                    <Panel header={<div className={"panel-header"}>
                        <Avatar circle style={{marginRight: 10}} src={LOGO_URL}/>
                        <h3>Stivisto Invoice Generator</h3>
                    </div>} bordered shaded>
                        <label>
                            Use this form to send an invoice to Stivisto Inc.
                        </label>
                        <PanelGroup>
                            <Panel>
                                <Form layout={"inline"} className={"form-common-data"}>
                                    <Form.Group controlId="datePicker">
                                        <Form.ControlLabel>Date:</Form.ControlLabel>
                                        <Form.Control
                                            name="datePicker"
                                            accepter={DatePicker}
                                            value={date}
                                            onChange={setDate}
                                            cleanable={false}
                                        />
                                    </Form.Group>
                                </Form>
                            </Panel>

                            <Panel header={<h4>From</h4>} bordered>
                                <Form fluid>
                                    <Form.Group controlId="name">
                                        <Form.ControlLabel>Name</Form.ControlLabel>
                                        <Form.Control
                                            name="name"
                                            type="email"
                                            placeholder={"What is your full name or business name?"}
                                            value={name}
                                            onChange={setName}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="address">
                                        <Form.ControlLabel>Address</Form.ControlLabel>
                                        <Form.Control
                                            rows={3}
                                            name="address"
                                            accepter={Textarea}
                                            value={address}
                                            onChange={setAddress}
                                            placeholder={"What is your address? (Including country)"}
                                        />
                                    </Form.Group>
                                </Form>
                            </Panel>

                            <Panel header={<h4>To</h4>}>
                                <Form fluid>
                                    <Form.Group controlId="name">
                                        <Form.ControlLabel>Name</Form.ControlLabel>
                                        <Form.Control
                                            name="name-control"
                                            type="email"
                                            value={STIVISTO_NAME}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="address">
                                        <Form.ControlLabel>Address</Form.ControlLabel>
                                        <Form.Control
                                            rows={3}
                                            name="textarea"
                                            accepter={Textarea}
                                            value={STIVISTO_ADDRESS}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </Form>
                            </Panel>

                            <Panel header={<h4>Product</h4>} bordered>
                                <Form fluid>
                                    <Form.Group>
                                        <Form.ControlLabel>Product</Form.ControlLabel>
                                        <Form.Control
                                            name="productName"
                                            type="email"
                                            placeholder={"What do you do for us?"}
                                            value={product}
                                            onChange={setProduct}
                                        />
                                    </Form.Group>
                                    <Form layout={"inline"}>
                                        <Form.Group>
                                            <Form.ControlLabel>Total</Form.ControlLabel>
                                            <Form.Control
                                                name="productTotal"
                                                type="email"
                                                value={total}
                                                onChange={setTotal}
                                                currency={currency}
                                                placeholder={"How much does it cost?"}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="currencyPicker">
                                            <Form.ControlLabel>Currency:</Form.ControlLabel>
                                            <Form.Control
                                                name="currencyPicker"
                                                accepter={SelectPicker}
                                                data={currencies}
                                                value={currency}
                                                onChange={setCurrency}
                                                cleanable={false}
                                                placement="topStart"
                                            />
                                        </Form.Group>
                                    </Form>
                                </Form>
                            </Panel>
                            <Button
                                block
                                appearance="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Download
                            </Button>
                        </PanelGroup>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Content>
        <div className='disabled'>
            <PDFDownloadLink
                className={"pdf-download-link"}
                document={<DefaultInvoicePDFTemplate
                    name={name}
                    address={address}
                    date={date}
                    currency={currency}
                    product={product}
                    total={total}
                />}
                fileName={fileName}
            >
                {({ blob, url, loading, error }) => {''}}
            </PDFDownloadLink>
        </div>
    </Container>
}

const _getRowSize = () => window.innerWidth > 1000 ? 12 : 24;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);
